// @flow
import * as React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import invariant from 'invariant';

import * as Zen from 'lib/Zen';
import AuthorizationService from 'services/AuthorizationService';
import CollapsibleLink from 'components/Navbar/CollapsibleLink';
import ConfigurationService, {
  CONFIGURATION_KEY,
} from 'services/ConfigurationService';
import CreateDashboardModal from 'components/common/CreateDashboardModal';
import DashboardService from 'services/DashboardBuilderApp/DashboardService';
import DashboardsFlyout from 'components/Navbar/DashboardsFlyout';
import DirectoryService from 'services/DirectoryService';
import Dropdown from 'components/ui/Dropdown';
import HelpButton from 'components/Navbar/HelpButton';
import HypertextLink from 'components/ui/HypertextLink';
import I18N from 'lib/I18N';
import Icon from 'components/ui/Icon';
import InfoTooltip from 'components/ui/InfoTooltip';
import Moment from 'models/core/wip/DateTime/Moment';
import MoreLinks from 'components/Navbar/MoreLinks';
import NavigationDropdown from 'components/Navbar/NavigationDropdown';
import Popover from 'components/ui/Popover';
import getCaseManagementEnabledStatus from 'services/CaseManagementService/getCaseManagementEnabledStatus';
import {
  ALERTS_PERMISSIONS,
  DASHBOARD_PERMISSIONS,
  RESOURCE_TYPES,
  SITE_PERMISSIONS,
} from 'services/AuthorizationService/registry';
import { NAVBAR_ID } from 'components/Navbar/constants';
import { VENDOR_SCRIPTS } from 'vendor/registry';
import {
  asButton,
  asDropdownOption,
  localizeUrl,
  onLinkClicked,
  isMobileBrowser,
  isUnoptimizedForMobile,
  isMobileView,
  addLocaleLabel,
  extractAcronym,
} from 'components/Navbar/util';
import { autobind } from 'decorators';
import { getEthiopianDateLabel } from 'util/dateUtil';
import type DashboardMeta from 'models/core/Dashboard/DashboardMeta';

const isPlatformSuspended = () => window.__JSON_FROM_BACKEND.ui.suspended;

type DefaultProps = {
  flagClass: string,

  /**
   * A callback that is invoked when the user wants to retrieve a list of all
   * dashboards.
   *
   * @returns {Promise<Zen.Array<DashboardMeta>>} A listing of all the
   *                                             dashboards.
   */
  lastDataUpdate: ?string,
  logoPath: string,
  visibleName: string,
};

type Props = {
  ...DefaultProps,
  fullPlatformName: string,
  isAuthenticated: boolean,
  username: string,
};

type State = {
  areDashboardsLoading: boolean,
  canCreateDashboards: boolean,
  canUploadData: boolean,
  canViewAlertsPage: boolean,
  canViewCaseManagement: boolean,
  canViewCatalogSetup: boolean,
  canViewDataCatalog: boolean,
  canViewDataQuality: boolean,
  canViewEntityMatching: boolean,
  canViewQueryForm: boolean,

  // the slug for the case management homepage dashboard (if there is one)
  caseManagementDashboardSlug: string | void,
  dashboards: Zen.Array<DashboardMeta>,
  isAdmin: boolean,
  isCaseManagementEnabled: boolean,
  isCrispEnabled: boolean,
  isMobileView: boolean,
  isValidationMode: boolean,
  moreOptionsCount: number,
  openDrawer: boolean,
  showAcronym: boolean,
  showCreateDashboardModal: boolean,
  showDashboardsFlyout: boolean,
  small: boolean,
};

// Mapping from state variable to the permission and resourceType that need to
// be checked with the authorization API.
const STATE_TO_AUTH = {
  canCreateDashboards: {
    permission: DASHBOARD_PERMISSIONS.CREATE,
    resourceType: RESOURCE_TYPES.DASHBOARD,
  },
  canUploadData: {
    permission: SITE_PERMISSIONS.CAN_UPLOAD_DATA,
    resourceType: RESOURCE_TYPES.SITE,
  },
  canViewAlertsPage: {
    permission: ALERTS_PERMISSIONS.CREATE,
    resourceType: RESOURCE_TYPES.ALERT,
  },
  canViewCaseManagement: {
    permission: SITE_PERMISSIONS.VIEW_CASE_MANAGEMENT,
    resourceType: RESOURCE_TYPES.SITE,
  },
  canViewCatalogSetup: {
    permission: SITE_PERMISSIONS.CAN_VIEW_FIELD_SETUP,
    resourceType: RESOURCE_TYPES.SITE,
  },
  canViewDataCatalog: {
    permission: SITE_PERMISSIONS.CAN_VIEW_DATA_CATALOG,
    resourceType: RESOURCE_TYPES.SITE,
  },
  canViewDataQuality: {
    permission: SITE_PERMISSIONS.VIEW_DATA_QUALITY,
    resourceType: RESOURCE_TYPES.SITE,
  },
  canViewEntityMatching: {
    permission: SITE_PERMISSIONS.CAN_VIEW_ENTITY_MATCHING,
    resourceType: RESOURCE_TYPES.SITE,
  },
  canViewQueryForm: {
    permission: SITE_PERMISSIONS.VIEW_QUERY_FORM,
    resourceType: RESOURCE_TYPES.SITE,
  },
  isAdmin: {
    permission: SITE_PERMISSIONS.VIEW_ADMIN_PAGE,
    resourceType: RESOURCE_TYPES.SITE,
  },
};

const ALERTS_URL = '/alerts';
const CASE_MANAGEMENT_URL = '/case-management';
const DATA_QUALITY_URL = '/data-quality';

// Matches navbar-transition-width in _zen_variables.scss
const TRANSITION_WIDTH = 1250;

// The width to switch from full deployment name to an acronym
const SHOW_ACRONYM_WIDTH = 320; // 320 small mobile screens

// The width to consider putting the last 2 items in the more dropdown
const HIDE_TWO_WIDTH = 1050;

// The width to consider putting the last 3 items in the more dropdown
const HIDE_THREE_WIDTH = 800;

// NOTE(stephen): We know that the document body will always be non-null.
// Cast it to a non-null type so that Flow is happy.
const DOCUMENT_BODY = ((document.body: $Cast): HTMLBodyElement);

function onSelection(
  value: (SyntheticEvent<HTMLElement>) => void,
  e: SyntheticEvent<HTMLElement>,
) {
  // The value stored is an onClick event we want to use.
  value(e);
}

function isSmall() {
  return DOCUMENT_BODY.clientWidth < TRANSITION_WIDTH;
}

function showAcronym() {
  return DOCUMENT_BODY.clientWidth < SHOW_ACRONYM_WIDTH;
}

function getMoreOptionsCount() {
  const deviceWidth = DOCUMENT_BODY.clientWidth;

  if (deviceWidth <= HIDE_THREE_WIDTH) {
    return 3;
  }

  if (deviceWidth <= HIDE_TWO_WIDTH) {
    return 2;
  }

  return 0;
}

export default class Navbar extends React.PureComponent<Props, State> {
  static defaultProps: DefaultProps = {
    flagClass: '',
    lastDataUpdate: null,
    logoPath: '',
    visibleName: '',
  };

  static renderToDOM(elementId?: string = 'header') {
    const elt: ?HTMLElement = document.getElementById(elementId);
    invariant(elt, `Element ID does not exist: ${elementId}`);

    const { ui, user } = window.__JSON_FROM_BACKEND;
    const { firstName, isAuthenticated, lastName, username } = user;
    const visibleName = firstName || lastName;
    const { flagClass, fullPlatformName, lastDataUpdate, logoPath } = ui;
    ReactDOM.render(
      <Navbar
        flagClass={flagClass}
        fullPlatformName={fullPlatformName}
        isAuthenticated={isAuthenticated}
        lastDataUpdate={lastDataUpdate}
        logoPath={logoPath}
        username={username}
        visibleName={visibleName}
      />,
      elt,
    );
  }

  state: State = {
    areDashboardsLoading: true,
    canCreateDashboards: false,
    canUploadData: false,
    canViewAlertsPage: false,
    canViewCaseManagement: false,
    canViewCatalogSetup: false,
    canViewDataCatalog: false,
    canViewDataQuality: false,
    canViewEntityMatching: false,
    canViewQueryForm: false,
    caseManagementDashboardSlug: undefined,
    dashboards: Zen.Array.create(),
    isAdmin: false,
    isCaseManagementEnabled: false,
    isCrispEnabled: false,
    isMobileView: isMobileView(),
    isValidationMode: false,
    moreOptionsCount: getMoreOptionsCount(),
    openDrawer: false,
    showAcronym: showAcronym(),
    showCreateDashboardModal: false,
    showDashboardsFlyout: false,
    small: isSmall(),
  };

  _dashboardsButtonRef: $ElementRefObject<'div'> = React.createRef();

  componentDidMount() {
    this.maybeShowMobileOptimizationDisclaimer();
    this.initializeDashboardsAndPermissions();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('offline', this.handleOffline);
    window.addEventListener('online', this.handleOnline);
    // Load toastr after the component mounts so that we do not delay the
    // rendering of the navbar.
    // TODO(stephen): It'd be nice to get rid of this preload dependency
    // altogether, but I have a feeling that other uses of toastr around the
    // site will break because they don't check if it is loaded.
    VENDOR_SCRIPTS.toastr.load();

    // NOTE(stephen): Do not put any permissions detection code inside this
    // function! Permissions (and configuration) checks require a user to be
    // authenticated. `initializeDashboardsAndPermissions` handles this. Place
    // your permission loading code there.
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('offline', this.handleOffline);
    window.removeEventListener('online', this.handleOnline);
  }

  @autobind
  openCreateDashboardModal() {
    this.setState({
      showCreateDashboardModal: true,
      showDashboardsFlyout: false,
    });
  }

  @autobind
  closeCreateDashboardModal() {
    this.setState({ showCreateDashboardModal: false });
  }

  @autobind
  openDashboardsFlyout() {
    this.setState({ showDashboardsFlyout: true });
    this.updateDashboardList();
  }

  @autobind
  closeDashboardsFlyout() {
    this.setState({ showDashboardsFlyout: false });
  }

  maybeShowMobileOptimizationDisclaimer() {
    const path = window.location.pathname;

    if (isMobileBrowser() && isUnoptimizedForMobile(path)) {
      window.toastr.warning(
        I18N.text(
          'This page is not optimized for use on mobile.',
          'notMobileOptimized',
        ),
      );
    }
  }

  @autobind
  handleResize() {
    this.setState({
      isMobileView: isMobileView(),
      moreOptionsCount: getMoreOptionsCount(),
      showAcronym: showAcronym(),
      small: isSmall(),
    });
  }

  initializeDashboardsAndPermissions() {
    // If the user is authenticated or public access is enabled, we can
    // initialize the dashboards and user permissions.
    const { isAuthenticated } = this.props;

    if (isAuthenticated && !isPlatformSuspended()) {
      this.updateDashboardList();
      AuthorizationService.isAuthorizedMulti(
        Object.keys(STATE_TO_AUTH).map(k => STATE_TO_AUTH[k]),
      ).then(authorizations => {
        // NOTE(stephen): n^2 loop is ok here because the number of
        // authorization checks we issue is small.
        const newState = {};
        Object.keys(STATE_TO_AUTH).forEach(stateKey => {
          const { permission, resourceType } = STATE_TO_AUTH[stateKey];
          authorizations.some(authorizationResponse => {
            if (
              authorizationResponse.permission === permission &&
              authorizationResponse.resourceType === resourceType
            ) {
              newState[stateKey] = authorizationResponse.authorized;
              return true;
            }
            return false;
          });
        });
        this.setState(newState);
      });

      // We cannot check configuration values when the user is not logged in,
      // even if the site has public accessibility.
      // TODO(stephen): God this is a mess. Find someone to help clean it up.
      if (isAuthenticated) {
        getCaseManagementEnabledStatus().then(isEnabled => {
          this.setState({ isCaseManagementEnabled: isEnabled });
        });

        ConfigurationService.getConfiguration(
          CONFIGURATION_KEY.CASE_MANAGEMENT_HOME_PAGE_DASHBOARD,
        ).then(homepage => {
          this.setState({
            caseManagementDashboardSlug: homepage.value(),
          });
        });

        ConfigurationService.getConfiguration(
          CONFIGURATION_KEY.CRISP_ENABLED,
        ).then(setting => {
          this.setState({ isCrispEnabled: setting.value() });
        });

        ConfigurationService.getConfiguration(
          CONFIGURATION_KEY.VALIDATION_MODE,
        ).then(setting => {
          this.setState({ isValidationMode: setting.value() });
          if (setting.value()) {
            window.toastr.warning(
              I18N.text(
                'The site is in validation mode. Exit validation mode in the Admin App.',
              ),
            );
          }
        });
      }
    }
  }

  updateDashboardList() {
    this.setState({ areDashboardsLoading: true });
    DashboardService.getDashboards().then(dashboards => {
      this.setState({
        areDashboardsLoading: false,
        dashboards: Zen.Array.create(dashboards),
      });
    });
  }

  handleOffline() {
    window.toastr.clear();

    // prevent toast from closing based on timeouts
    window.toastr.options.timeOut = 0;
    window.toastr.options.extendedTimeOut = 0;
    window.toastr.options.closeButton = true;
    window.toastr.error(
      I18N.text(
        'There is no Internet connection, please try reconnecting.',
        'offlineError',
      ),
    );
  }

  handleOnline() {
    // restore the defaults. See toastr source code for defaults clarity
    // https://github.com/CodeSeven/toastr/blob/master/toastr.js
    window.toastr.options.timeOut = 5000;
    window.toastr.options.extendedTimeOut = 1000;
    window.toastr.options.closeButton = false;
    window.toastr.clear();
  }

  _formatLastDataUpdate(lastDataUpdate: string): string {
    let timestamp = lastDataUpdate;
    if (!timestamp.endsWith('Z')) {
      timestamp += 'Z';
    }

    if (window.__JSON_FROM_BACKEND.enableEtDateSelection) {
      const time = new Date(timestamp).toLocaleString(undefined, {
        hour: '2-digit',
        minute: '2-digit',
      });
      return `${getEthiopianDateLabel(new Moment(timestamp))} ${time}`;
    }

    return new Date(timestamp).toLocaleString(undefined, {
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  }

  getSummaryInfo(): {
    dataUpdate: React.Element<'div'> | null,
    userStatus: React.Element<'div'> | null,
    versionInfo: React.Element<'div'> | null,
  } {
    const { isAuthenticated, lastDataUpdate, username } = this.props;
    const userStatus = isAuthenticated
      ? this.renderDropdownTitleItem(I18N.text('Logged in as'), username)
      : null;
    let dataUpdate = null;
    if (lastDataUpdate) {
      if (this.state.isValidationMode) {
        dataUpdate = this.renderDropdownTitleItem(
          <span className="navbar-dropdown-summary__warning-title">
            {I18N.text('Validation mode')}
          </span>,
          this._formatLastDataUpdate(lastDataUpdate),
          I18N.text(
            "Validation mode is used when new data is being QA'd on staging. The data may not be the latest version and may have bugs. You can exit validation mode in the Admin App Site Configuration tab.",
          ),
        );
      } else {
        dataUpdate = this.renderDropdownTitleItem(
          I18N.text('Last data refresh'),
          this._formatLastDataUpdate(lastDataUpdate),
          I18N.text(
            'Last data refresh refers to when the data pipeline started to run. This is typically when data starts being fetched from source systems. Certain data sources may be scheduled to fetch data on a certain day and time and would not align to this last data refresh time. Please contact an Administrator with any questions.',
          ),
        );
      }
    }

    const { isAdmin } = window.__JSON_FROM_BACKEND.user;
    const { buildTag } = window.__JSON_FROM_BACKEND;
    const versionInfo =
      isAdmin && buildTag
        ? this.renderDropdownTitleItem(I18N.text('Build version'), buildTag)
        : null;
    return {
      dataUpdate,
      userStatus,
      versionInfo,
    };
  }

  onHomeClicked(e: SyntheticMouseEvent<HTMLDivElement>) {
    onLinkClicked(localizeUrl('/overview'), e);
  }

  @autobind
  onHamburgerClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    this.setState(prevState => ({
      openDrawer: !prevState.openDrawer,
    }));
  }

  @autobind
  onUpdateDashboardIsFavorite(dashboard: DashboardMeta, isFavorite: boolean) {
    const newDashboard = dashboard.isFavorite(isFavorite);

    this.setState(
      prevState => {
        const index = prevState.dashboards.findIndex(
          currDashboard => currDashboard.slug() === dashboard.slug(),
        );
        const newDashboards = prevState.dashboards.set(index, newDashboard);

        return { dashboards: newDashboards };
      },
      () => DashboardService.markDashboardAsFavorite(dashboard, isFavorite),
    );
  }

  @autobind
  maybeRenderAnalyzeLink(
    isDropdownOption?: boolean = false,
    showDropdownIcon?: boolean = true,
  ): React.Node {
    const { isAuthenticated } = this.props;
    const { canViewQueryForm } = this.state;
    if (!isAuthenticated || !canViewQueryForm) {
      return null;
    }

    const url = localizeUrl('/advanced-query');
    const isActive = window.location.pathname.includes(url);
    const iconClassName = showDropdownIcon ? 'glyphicon glyphicon-search' : '';

    if (isDropdownOption) {
      return asDropdownOption(
        e => onLinkClicked(url, e),
        I18N.text('Analyze'),
        iconClassName,
      );
    }

    return (
      <HypertextLink
        key="analyze"
        onClick={e => onLinkClicked(url, e)}
        url={url}
      >
        {asButton(() => undefined, I18N.textById('Analyze'), isActive)}
      </HypertextLink>
    );
  }

  maybeRenderCreateDashboardModal(): React.Node {
    if (this.state.showCreateDashboardModal) {
      return (
        <CreateDashboardModal
          onRequestClose={this.closeCreateDashboardModal}
          show={this.state.showCreateDashboardModal}
        />
      );
    }

    return null;
  }

  @autobind
  maybeRenderDashboardsFlyoutButton(): React.Node {
    const {
      areDashboardsLoading,
      canCreateDashboards,
      caseManagementDashboardSlug,
      dashboards,
      showDashboardsFlyout,
    } = this.state;

    if (this.props.isAuthenticated && !isPlatformSuspended()) {
      const locationPath = window.location.pathname;
      const isCaseManagementDashboard =
        caseManagementDashboardSlug &&
        locationPath.includes(caseManagementDashboardSlug);

      const isActive =
        window.location.pathname.includes('/dashboard') &&
        !isCaseManagementDashboard;

      const className = classNames('dashboards-dropdown-button', {
        'navbar-item--active': isActive,
      });

      return (
        <div
          key="dashboards-dropdown"
          ref={this._dashboardsButtonRef}
          className={className}
        >
          {asButton(
            this.openDashboardsFlyout,
            I18N.textById('Dashboards'),
            false,
            null,
            'navbar-dashboards-flyout-button',
          )}
          <Popover
            anchorElt={this._dashboardsButtonRef.current}
            isOpen={showDashboardsFlyout}
            onRequestClose={this.closeDashboardsFlyout}
            windowEdgeThresholds={{
              bottom: 0,
              left: 0,
              right: 0,
              // Prevents the popover overlaying the navbar on small screens.
              top: 60,
            }}
          >
            <DashboardsFlyout
              activeUsername={DirectoryService.getActiveUsername()}
              canCreateDashboards={canCreateDashboards}
              dashboards={dashboards}
              dashboardsLoaded={!areDashboardsLoading}
              onNewDashboardClick={this.openCreateDashboardModal}
              onUpdateDashboardIsFavorite={this.onUpdateDashboardIsFavorite}
            />
          </Popover>
        </div>
      );
    }
    return null;
  }

  maybeRenderLastDataRefresh(): React.Node {
    const { lastDataUpdate } = this.props;
    if (lastDataUpdate) {
      const formattedDataUpdate = this._formatLastDataUpdate(lastDataUpdate);
      if (this.state.small) {
        return (
          <p>{`${I18N.textById(
            'Last data refresh',
          )}: ${formattedDataUpdate}`}</p>
        );
      }
      return ` | ${I18N.textById('Last data refresh')}: ${formattedDataUpdate}`;
    }
    return null;
  }

  maybeRenderLoggedInStatus(): React.Node {
    const { isAuthenticated, username } = this.props;
    if (isAuthenticated) {
      return ` | ${I18N.textById('Logged in as')} ${username}`;
    }
    return null;
  }

  @autobind
  maybeRenderCaseManagementLink(
    isDropdownOption?: boolean = false,
    showDropdownIcon?: boolean = true,
  ): React.Node {
    const { isAuthenticated } = this.props;
    const {
      canViewCaseManagement,
      caseManagementDashboardSlug,
      isCaseManagementEnabled,
    } = this.state;
    const { caseManagementAppOptions } = window.__JSON_FROM_BACKEND;
    if (
      isAuthenticated &&
      canViewCaseManagement &&
      isCaseManagementEnabled &&
      caseManagementAppOptions.showInNavbar
    ) {
      const url = localizeUrl(CASE_MANAGEMENT_URL);
      const locationPath = window.location.pathname;
      const isActive =
        locationPath.includes(url) ||
        (caseManagementDashboardSlug &&
          locationPath.includes(`dashboard/${caseManagementDashboardSlug}`));
      const iconClassName = showDropdownIcon
        ? 'glyphicon glyphicon-folder-open'
        : '';

      if (isDropdownOption) {
        return asDropdownOption(
          e => onLinkClicked(url, e),
          // TODO(pablo): the CMA title should be handled through a config,
          // not hardcoded in the JSON_FROM_BACKEND
          caseManagementAppOptions.navbarTitle,
          iconClassName,
        );
      }

      return (
        <HypertextLink
          key="case-management"
          onClick={e => onLinkClicked(url, e)}
          url={url}
        >
          {asButton(
            () => undefined,
            caseManagementAppOptions.navbarTitle,
            isActive,
          )}
        </HypertextLink>
      );
    }
    return null;
  }

  maybeRenderDrawer(): React.Node {
    const { isAuthenticated } = this.props;
    const { locales, ui } = window.__JSON_FROM_BACKEND;
    const {
      canUploadData,
      canViewCatalogSetup,
      canViewDataCatalog,
      canViewEntityMatching,
      isAdmin,
      openDrawer,
    } = this.state;

    if (!openDrawer) {
      return null;
    }

    return (
      <div className="navbar__menu-container">
        {this.renderMobileSummaryInfo()}
        <div>
          {this.maybeRenderAnalyzeLink()}
          {this.maybeRenderDataQualityLink()}
          {this.maybeRenderCaseManagementLink()}
          {this.maybeRenderAlertsLink()}
        </div>
        <CollapsibleLink
          className="navbar-item__more-links"
          label="More"
          openClassName="navbar-item__more-links--open"
        >
          <MoreLinks
            isAdmin={isAdmin}
            isAuthenticated={isAuthenticated}
            linksAsDropdownOptions={false}
            locales={addLocaleLabel(locales)}
            showCatalogSetup={canViewCatalogSetup}
            showDataCatalog={canViewDataCatalog}
            showDataUpload={canUploadData}
            showEntityMatching={canViewEntityMatching}
            showLocales={ui.showLocalePicker}
          />
        </CollapsibleLink>
      </div>
    );
  }

  @autobind
  maybeRenderAlertsLink(
    isDropdownOption?: boolean = false,
    showDropdownIcon?: boolean = true,
  ): React.Node {
    if (!this.state.canViewAlertsPage) {
      return null;
    }

    const { alertsEnabled } = window.__JSON_FROM_BACKEND;
    if (!alertsEnabled) {
      return null;
    }

    const url = localizeUrl(ALERTS_URL);
    const isActive = window.location.pathname.includes(url);
    const iconClassName = showDropdownIcon ? 'glyphicon glyphicon-search' : '';

    if (isDropdownOption) {
      return asDropdownOption(
        e => onLinkClicked(url, e),
        I18N.textById('Alerts'),
        iconClassName,
      );
    }

    return (
      <HypertextLink
        key="alerts"
        onClick={e => onLinkClicked(url, e)}
        url={url}
      >
        {asButton(() => undefined, I18N.textById('Alerts'), isActive)}
      </HypertextLink>
    );
  }

  maybeRenderMoreOptionsDropdown(
    children: $ReadOnlyArray<?React.Element<
      Class<Dropdown.Option<(SyntheticEvent<HTMLElement>) => void>>,
    >>,
  ): React.Node {
    const showMoreOptionsDropdown =
      this.state.moreOptionsCount > 0 && children.length > 0;

    if (!showMoreOptionsDropdown || !this.props.isAuthenticated) {
      return null;
    }

    return (
      <Dropdown
        buttonClassName="navbar-item"
        caretType={Dropdown.CaretTypes.MENU}
        defaultDisplayContent={I18N.text('More')}
        displayCurrentSelection={false}
        hideCaret={false}
        menuAlignment={Dropdown.Alignments.RIGHT}
        menuClassName="navbar-dropdown-menu navbar-more-links__menu"
        onSelectionChange={onSelection}
        value={undefined}
      >
        {children}
      </Dropdown>
    );
  }

  @autobind
  maybeRenderDataQualityLink(isDropdownOption?: boolean = false): React.Node {
    if (
      !this.state.canViewDataQuality ||
      !this.props.isAuthenticated ||
      !window.__JSON_FROM_BACKEND.ui.enableDataQualityLab
    ) {
      return null;
    }

    const url = localizeUrl(DATA_QUALITY_URL);
    const isActive = window.location.pathname.includes(url);

    if (isDropdownOption) {
      return asDropdownOption(
        e => onLinkClicked(localizeUrl(DATA_QUALITY_URL), e),
        I18N.text('Data Quality'),
        '',
        null,
      );
    }

    return (
      <HypertextLink
        key="data-quality"
        onClick={e => onLinkClicked(url, e)}
        url={url}
      >
        {asButton(() => undefined, I18N.textById('Data Quality'), isActive)}
      </HypertextLink>
    );
  }

  renderDropdownTitleItem(
    titleName: React.Node,
    value: string,
    tooltipText?: string,
  ): React.Element<'div'> {
    return (
      <div className="navbar-dropdown-summary__item">
        <div className="navbar-dropdown-summary__title-name">
          {titleName}
          {tooltipText ? <InfoTooltip text={tooltipText} /> : null}
        </div>
        <div className="navbar-dropdown-summary__title-value">{value}</div>
      </div>
    );
  }

  renderDropdownSummaryTitle(): React.Element<typeof Dropdown.Option> {
    const { dataUpdate, userStatus, versionInfo } = this.getSummaryInfo();

    return (
      <Dropdown.Option
        key="summary"
        className="navbar-dropdown-summary__title"
        disableSearch
        value="__unused__"
        wrapperClassName="navbar-dropdown-summary"
      >
        {userStatus}
        {dataUpdate}
        {versionInfo}
      </Dropdown.Option>
    );
  }

  renderMobileSummaryInfo(): React.Node {
    const { dataUpdate, userStatus, versionInfo } = this.getSummaryInfo();
    return (
      <div className="navbar-mobile-summary-container">
        {userStatus}
        {dataUpdate}
        {versionInfo}
      </div>
    );
  }

  renderFullNavbar(): React.Node {
    const { isCrispEnabled, moreOptionsCount } = this.state;
    let leftAlignedLinks = [
      this.maybeRenderAnalyzeLink,
      this.maybeRenderDashboardsFlyoutButton,
      this.maybeRenderDataQualityLink,
      this.maybeRenderCaseManagementLink,
      this.maybeRenderAlertsLink,
    ];

    // filter out render functions that are null for a deployment
    leftAlignedLinks = leftAlignedLinks.filter(f => f());
    let moreDropdownLinks = [];

    if (moreOptionsCount > 0) {
      // ensure that a minimum of 2 links are displayed before the more dropdown
      const leftAlignedLinksCount = Math.max(
        2,
        leftAlignedLinks.length - moreOptionsCount,
      );

      // only add items to more dropdown if more than 1 item exists
      if (leftAlignedLinks.length - leftAlignedLinksCount > 1) {
        moreDropdownLinks = leftAlignedLinks.slice(leftAlignedLinksCount);
        leftAlignedLinks = leftAlignedLinks.slice(0, leftAlignedLinksCount);
      }
    }

    return (
      <React.Fragment>
        <div className="navbar-items__left">
          {leftAlignedLinks.map(renderLink => renderLink())}
          {this.maybeRenderMoreOptionsDropdown(
            // $FlowFixMe[extra-arg] - this is not a good pattern
            // $FlowFixMe[incompatible-call] - this is not a good pattern
            // $FlowFixMe[incompatible-exact] - this is not a good pattern
            moreDropdownLinks.map(renderLink => renderLink(true, false)),
          )}
        </div>
        <div className="navbar-items__right">
          {isCrispEnabled && <HelpButton />}
          {this.renderNavigationDropdown()}
        </div>
      </React.Fragment>
    );
  }

  renderMobileNavbar(): React.Node {
    return (
      <React.Fragment>
        <div className="navbar-items__left">
          {this.maybeRenderDashboardsFlyoutButton()}
        </div>
        <div className="navbar-items__right">
          <button
            className="navbar-item"
            onClick={this.onHamburgerClick}
            type="button"
          >
            <Icon type="option-horizontal" />
          </button>
        </div>
        {this.maybeRenderDrawer()}
      </React.Fragment>
    );
  }

  renderNavigationDropdown(): React.Node {
    const { isAuthenticated, visibleName } = this.props;
    const { locales, ui } = window.__JSON_FROM_BACKEND;

    return (
      <NavigationDropdown
        isAdmin={this.state.isAdmin}
        isAuthenticated={isAuthenticated}
        locales={addLocaleLabel(locales)}
        showCatalogSetup={this.state.canViewCatalogSetup}
        showDataCatalog={this.state.canViewDataCatalog}
        showDataUpload={this.state.canUploadData}
        showEntityMatching={this.state.canViewEntityMatching}
        showLocales={ui.showLocalePicker}
        visibleName={visibleName}
      >
        {this.renderDropdownSummaryTitle()}
      </NavigationDropdown>
    );
  }

  renderTitleContainer(): React.Node {
    const { flagClass, fullPlatformName, logoPath } = this.props;
    let platformName = fullPlatformName;

    if (this.state.showAcronym) {
      platformName = extractAcronym(fullPlatformName);
    }

    const logo = logoPath ? (
      <img alt="logo" src={logoPath} />
    ) : (
      <i className={`flag ${flagClass}`} />
    );

    // HACK(nina): $GatesMalariaDemoHack - Hide platform name from nav bar
    const platformTitle =
      window.__JSON_FROM_BACKEND.deploymentName === 'gates_malaria' ? (
        undefined
      ) : (
        <span className="navbar-title-container__title">{platformName}</span>
      );

    return (
      <button
        className="navbar-title-container"
        onClick={this.onHomeClicked}
        type="button"
      >
        <span className="navbar-title-container__logo">{logo}</span>
        {platformTitle}
      </button>
    );
  }

  renderNavbar(): React.Node {
    const navbarItems = this.state.isMobileView
      ? this.renderMobileNavbar()
      : this.renderFullNavbar();
    return (
      <React.Fragment>
        <div className="navbar-items">{navbarItems}</div>
        {this.maybeRenderCreateDashboardModal()}
      </React.Fragment>
    );
  }

  render(): React.Node {
    // HACK(nina): $GatesMalariaDemoHack - change navbar style
    const className = classNames('navbar', 'hide-in-screenshot', {
      'navbar-gates-malaria':
        window.__JSON_FROM_BACKEND.deploymentName === 'gates_malaria',
      'navbar-mobile': this.state.isMobileView,
    });
    return (
      <div className={className} id={NAVBAR_ID}>
        {this.renderTitleContainer()}
        {this.renderNavbar()}
      </div>
    );
  }
}
