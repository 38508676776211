// @flow

import React from 'react';

import Group from 'components/ui/Group';
import Spacing from 'components/ui/Spacing';

type Props = {
  children: React$Node,
};

const AuthLayout = ({ children }: Props): React$Node => {
  return (
    <Spacing alignItems="center" className="auth-container" flex>
      <Group.Vertical className="auth-layout">{children}</Group.Vertical>
    </Spacing>
  );
};

export default AuthLayout;
